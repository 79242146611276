import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import cn from 'classnames'
import dayjs from 'dayjs'

import Page from '../../components/Page'
import PageIntro from '../../components/PageIntro'
import Newsletter from '../../components/Newsletter'
import Container from '../../components/elements/Container'

const humanDate = (date) => dayjs(date).format('DD MMM YYYY')

const JobPostTemplate = ({ data }) => {
  const { jobPost } = data

  return (
    <Page className="p-careers__ad has-fingerPrints">
      <HelmetDatoCms seo={jobPost.seoMetaTags} />

      <PageIntro
        title="<strong>Careers</strong>"
        text=""
        image="careers/Careers-Icon-Job"
      />

      <section className="c-section">
        <Container className="c-section__outer">
          <div className="c-section__inner pt-0">
            <div className="row mb-5">
              <div className="col-12">
                <h1>{jobPost.title}</h1>
                <p className="text-muted">{`${jobPost.location} - ${jobPost.salary} - ${jobPost.contractType}`}</p>
                {jobPost.closingDate && (
                  <CloseDate date={jobPost.closingDate} />
                )}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <h2>Introduction</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html: jobPost.introductionNode.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <h2>About Erudus</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html: jobPost.aboutTheCompanyNode.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <h2>Role Overview</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html: jobPost.roleOverviewNode.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <h2>Requirements</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html:
                    jobPost.positionRequirementsNode.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <h2>Benefits</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html: jobPost.benefitsNode.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="row">
              <div className="col-12">
                <h2>How to apply</h2>
              </div>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{
                  __html: jobPost.howToApplyNode.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </Container>
      </section>

      <Newsletter />
    </Page>
  )
}

export default JobPostTemplate

export const pageQuery = graphql`
  query JobPostQuery($slug: String!) {
    jobPost: datoCmsJob(slug: { eq: $slug }) {
      title: jobTitle
      slug
      location
      contractType
      department
      closingDate
      salary
      introductionNode {
        childMarkdownRemark {
          html
        }
      }
      roleOverviewNode {
        childMarkdownRemark {
          html
        }
      }
      positionRequirementsNode {
        childMarkdownRemark {
          html
        }
      }
      aboutTheCompanyNode {
        childMarkdownRemark {
          html
        }
      }
      benefitsNode {
        childMarkdownRemark {
          html
        }
      }
      howToApplyNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

const CloseDate = ({ date }) => {
  return dayjs().isAfter(date, 'day') ? (
    <p className="small">This job is now closed</p>
  ) : (
    <p className="small">
      Closing date: <time dateTime={date}>{humanDate(date)}</time>
    </p>
  )
}
