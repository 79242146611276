import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from './elements/Container'

const style = `
  .c-section--pageHeader .c-section__outer {
    background-size: 0;
  }

  @media (min-width: 768px) {
    .c-section--pageHeader .c-section__outer {
      background-repeat: no-repeat;
      background-position: 95% 4rem;
      background-size: 150px;
    }
  }
`

const PageIntro = ({ title, text, image }) => (
  <section className="c-section c-section--pageHeader c-section--pageHeader--background">
    <style>{style}</style>
    <Container
      className="c-section__outer"
      style={
        image && {
          backgroundImage: `url(/images/icons/${image}.svg)`,
        }
      }
    >
      <div className="c-section__inner">
        <div className="c-pageHeader">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-3">
              <span
                className="c-pageHeader__title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
            <div className="col-12 col-md-9 col-lg-6 col-xl-7">
              <h1
                className="c-pageHeader__intro"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>

    <div className="c-wave c-wave--bottom">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none' }}
          d="M-5.92,115.95 C125.56,-215.63 292.04,320.22 500.84,21.20 L500.00,150.00 L0.00,150.00 Z"
        ></path>
      </svg>
    </div>
  </section>
)

PageIntro.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default PageIntro

export const FragmentQuery = graphql`
  fragment PageIntroFragment on MarkdownRemarkFrontmatterPageIntro {
    title
    text
    image
  }
`
